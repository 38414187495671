import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {rem, position} from 'polished';

import {iconColor, primaryColor} from 'helpers/themehelpers';
import {colors, borderRadiuses, fontSizes} from 'helpers/variables';
import {gapable} from 'helpers/traits';
import {mq} from 'helpers/stylehelpers';
import Icon from './icon';

const Wrapper = styled.div`
    ${gapable()};
    width: 100%;

    ${mq.medium`
        align-items: center;
        display: flex;
    `};
`;

/** InnerWrapper */
const InnerWrapper = styled.div`
    border-radius: ${borderRadiuses.s};
    border: 1px solid ${colors.gray300};
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;

    /* White overlay to hide the default select dropdown icon */
    &::before {
        background: ${colors.white};
        content: '';
        display: block;
        pointer-events: none;
        width: ${rem(24)};
        ${position('absolute', 0, 0, 0, 'auto')};
    }
`;

/** Dropdown Icon */
const IconWrapper = styled.span`
    align-items: center;
    background-color: ${primaryColor};
    border-radius: 50%;
    color: ${colors.white};
    content: '';
    display: flex;
    height: 1.375em;
    justify-content: center;
    pointer-events: none;
    transform: translateY(-50%);
    transition: transform 150ms;
    width: 1.375em;
    ${position('absolute', '50%', rem(14), 'auto', 'auto')};
`;

/** Select */
const Select = styled.select`
    -webkit-appearance: none;
    background: ${colors.white};
    border: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1.5;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: ${rem(8)} ${rem(25)} ${rem(8)} ${rem(10)};
    width: 100%;

    &:focus + ${IconWrapper} {
        transform: translateY(-50%) rotate(180deg);
    }
`;

/** Label */
const Label = styled.label`
    ${fontSizes.s};
    color: ${colors.textColor};
    display: block;
    margin-bottom: 0.4em;

    ${mq.medium`
        margin-bottom: 0;
        margin-right: 0.5em;
    `};
`;

/** Angepasster Icon */
const StyledIcon = styled(Icon)`
    color: ${iconColor};
`;

/**
 * Erzeugt ein Dropdown-Element
 * @param {object} props.children Die Options
 * @param {string} props.name Der Name des Select-Felds
 * @param {function} props.onChange Der Change-Listener (mit .blur()-Code Rücknahme des :focus-Styles)
 */
const Dropdown = ({children, onChange, name, label, gap, ...otherProps}) => (
    <Wrapper gap={gap}>
        {label && <Label>{label}</Label>}
        <InnerWrapper>
            <Select
                onChange={evt =>
                    (typeof onChange === 'function' && onChange(evt))
                }
                name={name}
                {...otherProps}
            >
                {children}
            </Select>
            <IconWrapper>
                <StyledIcon type="angleDown"/>
            </IconWrapper>
        </InnerWrapper>
    </Wrapper>
);
Dropdown.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    gap: gapable.propType,
};

Dropdown.defaultProps = {
    name: null,
    onChange: null,
    label: null,
    gap: 'none',
};

export default Dropdown;
