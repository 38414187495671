import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Modal from 'react-modal';

import { colors, gaps, fontSizes } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import { headlineColor } from 'helpers/themehelpers';

import Container from './container';
import Toggle from './toggle';
import Button from './button';
import { Headline } from './typography';

Modal.setAppElement(process.env.GATSBY_BUILD_STAGE ? '#___gatsby' : '#root');

/** Custom-Styles für React-Modal */
const customStyles = {
    overlay: {
        zIndex: 1002,
        backgroundColor: rgba(colors.black, 0.65),
    },
    content: {
        border: 0,
        borderRadius: 0,
        padding: '1em 1.25em',
        boxShadow: `0 0 5px 5px ${rgba(colors.black, 0.25)}`,

        left: '5%',
        top: 0,
        right: 0,
        bottom: 0,
    },
};

/** Toggler zum schließen */
const StyledToggle = styled(Toggle)`
    position: absolute;
    right: 0;
    top: 0;
`;

/* Gewrappter Container */
const Wrapper = styled(Container)`
    position: relative;
    padding: 3em 0 !important;
    ${mq.large`
        padding: 3em 1em !important;
    `};
`;

/** Nachfolgen Styles um das vermurkste HTML von Concludis halbwegs hinzubekommen */

const subheadlineStyles = css`
    color: ${headlineColor};
    margin: 0;
    font-weight: normal;
    ${fontSizes.h3};
    ${gaps.m};
`;

const Teaser = styled.div`
    line-height: 1.5;
    ${gaps.xl};
    strong {
        display: block;
        color: ${headlineColor};
        ${fontSizes.h3};
        ${gaps.m};
    }
    ul {
        margin-bottom: 0;
    }
`;

const Subheadline = styled.div`
    h3 {
        ${subheadlineStyles};
    }
`;

const List = styled.div`
    ul {
        margin: 0;
        ${gaps.xl};
    }
`;

const Textblock = styled.div`
    margin: 0;
    ${gaps.xl};
    br:nth-child(2n) {
        display: none;
    }
    strong {
        ${subheadlineStyles};
    }
`;

/**
 * Zeigt ein Modal, mit den JobDetails
 * @param {booean} isOpen Flag, ob Modal angezeigt wird oder nicht
 * @param {func} onRequestClose Methode, die das Modal schließt
 * @param {object} jobDetail Details für den Job
 */
const JobModal = ({ isOpen, onRequestClose, jobDetails }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        closeTimeoutMS={350}
    >
        <Wrapper size="l">
            <StyledToggle icon="close" onClick={onRequestClose} />
            {jobDetails && (
                <>
                    {jobDetails.teaser && (
                        <Teaser
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.teaser,
                            }}
                        />
                    )}
                    {jobDetails.title && (
                        <Headline level="h2" gap="l">
                            {jobDetails.title}
                        </Headline>
                    )}
                    {jobDetails.description && (
                        <Teaser
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.description,
                            }}
                        />
                    )}
                    {jobDetails.taskHeadline && (
                        <Subheadline
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.taskHeadline,
                            }}
                        />
                    )}
                    {jobDetails.tasks && (
                        <List
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.tasks,
                            }}
                        />
                    )}
                    {jobDetails.requirementsHeadline && (
                        <Subheadline
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.requirementsHeadline,
                            }}
                        />
                    )}
                    {jobDetails.requirements && (
                        <List
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.requirements,
                            }}
                        />
                    )}
                    {jobDetails.featuresHeadline && (
                        <Subheadline
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.featuresHeadline,
                            }}
                        />
                    )}
                    {jobDetails.features && (
                        <Textblock
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.features,
                            }}
                        />
                    )}
                    {jobDetails.ctaHeadline && (
                        <Subheadline
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.ctaHeadline,
                            }}
                        />
                    )}
                    {jobDetails.cta && (
                        <Textblock
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.cta,
                            }}
                        />
                    )}
                    {jobDetails.contact && (
                        <Textblock
                            dangerouslySetInnerHTML={{
                                __html: jobDetails.contact.replace(/<div>(&nbsp;|)<\/div>/g, ''),
                            }}
                        />
                    )}
                    {jobDetails.applyUrl && (
                        <Button linkTo={jobDetails.applyUrl}>Jetzt bewerben!</Button>
                    )}
                </>
            )}
        </Wrapper>
    </Modal>
);

JobModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    jobDetails: PropTypes.shape({
        applyUrl: PropTypes.string,
        contact: PropTypes.string,
        cta: PropTypes.string,
        ctaHeadline: PropTypes.string,
        description: PropTypes.string,
        features: PropTypes.string,
        featuresHeadline: PropTypes.string,
        requirements: PropTypes.string,
        requirementsHeadline: PropTypes.string,
        tasks: PropTypes.string,
        tasksHeadline: PropTypes.string,
        teaser: PropTypes.string,
        title: PropTypes.string,
    }),
    onRequestClose: PropTypes.func.isRequired,
};

JobModal.defaultProps = {
    jobDetails: null,
};

export default JobModal;
