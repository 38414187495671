import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';

import { rgba, hideText } from 'polished';
import { primaryColor } from 'helpers/themehelpers';
import { colors } from 'helpers/variables';

export const primaryColorAlpha = theme('main', {
    sbf: rgba(colors.sbf.primary, 0.2),
    vielLeicht: rgba(colors.vielLeicht.primary, 0.2),
});

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    ${hideText()};
    animation: ${rotate} 1.1s infinite linear;
    border-top: 0.9em solid ${primaryColorAlpha}};
    border-right: 0.9em solid ${primaryColorAlpha};
    border-bottom: 0.9em solid ${primaryColorAlpha};
    border-left: 0.9em solid ${primaryColor};
    font-size: 10px;
    margin: auto;
    position: relative;
    transform: translateZ(0);

    &,
    &::after {
        border-radius: 50%;
        width: 7em;
        height: 7em;
    }
`;

export default Spinner;
