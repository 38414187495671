import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import flatten from 'lodash/flatten';
import { rem } from 'polished';
import { mq } from 'helpers/stylehelpers';

import { textlinkColor } from 'helpers/themehelpers';
import { colors } from 'helpers/variables';
import { gapable, roundable } from 'helpers/traits';
import { fetchConcludisJobs, getObjectByValue, log } from 'helpers/utils';

import Button from './button';
import Icon from './icon';
import Spinner from './spinner';
import Filter from './filter';
import JobModal from './job-modal';

/** Wrapper */
const Wrapper = styled.div`
    ${gapable()};
`;

/** Fehler bzw. Meldungsbox */
const Messagebox = styled.div`
    border: 1px solid ${colors.gray300};
    padding: 1em;
    text-align: center;
    ${roundable('m')};
    ${({ error }) =>
        error &&
        `
        background-color: ${colors.alert};
        border: none;
    `};
`;

/** Spinner */
const StyledSpinner = styled(Spinner)`
    border-width: ${rem(4)};
    &,
    &::after {
        height: ${rem(30)};
        width: ${rem(30)};
    }
    margin: 0 auto;
`;

/** Listen-Element */
const Item = styled.div`
    border-bottom: 1px solid ${colors.gray300};
    padding: 0.75em 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    ${mq.medium`
        align-items: center;
        display: flex;
        padding: 1em 0;
    `};
`;

/** Titel des Stellenangebots */
const Title = styled.strong`
    display: block;
    font-weight: normal;
    cursor: pointer;
    width: 100%;

    ${mq.medium`
        margin-right: 1em;
    `};
`;

/** Gestylter Button, der mobil zum einfachen Textlink umgestyled wird */
const StyledButton = styled(Button)`
    ${mq.smallOnly`
        &, &:hover {
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: ${textlinkColor}
            margin: 0.35em 0 0;
            padding: 0;
        }
    `};
`;

/** Gestylter Icon */
const StyledIcon = styled(Icon)`
    margin-right: 0.5em;
    ${mq.medium`
        display: none !important;
    `};
`;

/**
 * Enfernt Duplikate aus einem Array
 * @param {array} array Das zu bearbeitende Array
 * @return {array} Das aufgeräumte Array
 */
const removeDuplicates = array => {
    let cleanedArray = array;
    cleanedArray = cleanedArray.filter((item, index) => array.indexOf(item) === index);
    return cleanedArray;
};

/**
 * Bereitet den Murks von Concludis auf
 * @param {object} data Daten von Concludis
 * @return {object} Verwendbare Daten
 */
const prepareJobData = data => {
    const concludisJobs = Object.values(data);
    concludisJobs.pop();

    // API-Daten aufbereiten
    const jobs = concludisJobs.map(job => ({
        id: job.job_id && job.job_id,
        title: job.job_position_title && job.job_position_title,
        teaser: job.job_ad.job_ad_container['2'] && job.job_ad.job_ad_container['2'].contenthtml,
        description:
            job.job_ad.job_ad_container['3'] && job.job_ad.job_ad_container['3'].contenthtml,
        taskHeadline:
            job.job_ad.job_ad_container['14'] && job.job_ad.job_ad_container['14'].contenthtml,
        tasks: job.job_ad.job_ad_container['4'] && job.job_ad.job_ad_container['4'].contenthtml,
        requirementsHeadline:
            job.job_ad.job_ad_container['15'] && job.job_ad.job_ad_container['15'].contenthtml,
        requirements:
            job.job_ad.job_ad_container['5'] && job.job_ad.job_ad_container['5'].contenthtml,
        featuresHeadline:
            job.job_ad.job_ad_container['16'] && job.job_ad.job_ad_container['16'].contenthtml,
        features: job.job_ad.job_ad_container['6'] && job.job_ad.job_ad_container['6'].contenthtml,
        ctaHeadline:
            job.job_ad.job_ad_container['17'] && job.job_ad.job_ad_container['17'].contenthtml,
        cta: job.job_ad.job_ad_container['7'] && job.job_ad.job_ad_container['7'].contenthtml,
        contact: job.job_ad.job_ad_container['10'] && job.job_ad.job_ad_container['10'].contenthtml,
        contractType: job.job_classification.name && job.job_classification.name,
        applyUrl: job.job_apply_url && job.job_apply_url,
        operationAreas:
            job.job_group1 && Object.values(job.job_group1).map(item => item.job_group1_name),
    }));

    // Einsatzbereiche für Filter aufbereiten
    const filterItemsOperationAreas = flatten(
        removeDuplicates(jobs.map(({ operationAreas }) => operationAreas))
    ).map(operationArea => ({
        name: operationArea,
        value: operationArea,
    }));

    filterItemsOperationAreas.unshift({ name: 'Einsatzgebiet', value: '*' });

    const filterItemsContractTypes = removeDuplicates(
        jobs.map(({ contractType }) => contractType)
    ).map(contractType => ({
        name: contractType,
        value: contractType,
    }));
    filterItemsContractTypes.unshift({ name: 'Vertragsart', value: '*' });

    return {
        jobs,
        filterItemsOperationAreas,
        filterItemsContractTypes,
    };
};

/**
 * Komponente EINES JobEintrags
 */
const JobItem = ({ title, openJobDetail }) => (
    <Item>
        <Title textColor="black" onClick={openJobDetail}>
            {title}
        </Title>
        <StyledButton size="s" rounded layer={1} onClick={openJobDetail} test="foo">
            <StyledIcon type="angleRight" />
            Details
        </StyledButton>
    </Item>
);

JobItem.propTypes = {
    openJobDetail: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

/**
 * Erzeugt eine Liste mit den Stellenangeboten aus der Concludis-Schnittstelle
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.limit Limit der anzuzeigende Jobs
 * @param {boolean} props.showFilter Flag, ob Filter angezeigt wird oder nicht
 */
const Joblist = ({ gap, limit, showFilter }) => {
    // Jobs im State
    const [jobData, setJobData] = useState(null);
    const [loadError, setLoadError] = useState(null);
    const [currentJobDetails, setCurrentJobDetails] = useState(null);

    // Öffnet das Panel
    const openJobDetail = id => {
        setCurrentJobDetails(getObjectByValue(jobData.jobs, 'id', id, true));
        document.getElementsByTagName('body')[0].classList.add('modal-active');
    };

    // Schließt das Panel
    const closeJobDetail = () => {
        setCurrentJobDetails(null);
        document.getElementsByTagName('body')[0].classList.remove('modal-active');
    };

    // Daten holen
    useEffect(() => {
        fetchConcludisJobs()
            .then(data => {
                console.log(data);
                // Daten in State packen
                setJobData(prepareJobData(data));
            })
            .catch(err => {
                console.log('hu?');
                setLoadError(true);
                log(err);
            });
    }, []);

    return (
        <>
            {jobData && jobData.jobs.length > 0 && (
                <JobModal
                    isOpen={currentJobDetails !== null}
                    onRequestClose={closeJobDetail}
                    jobDetails={currentJobDetails}
                />
            )}

            <Wrapper gap={gap}>
                {/* Lade-Fehler */}
                {loadError && (
                    <Messagebox error>
                        Es ist ein Fehler beim Laden der Stellenangebote aufgetreten.
                    </Messagebox>
                )}

                {/* Keine Jobs vorhanden */}
                {jobData && !jobData.jobs.length > 0 && (
                    <Messagebox>Momentan sind keine offenen Stellenangebote vorhanden.</Messagebox>
                )}

                {/* Loading */}
                {!jobData && !loadError && <StyledSpinner />}

                {/* Version mit oder ohne Filter */}
                {jobData && jobData.jobs.length > 0 && showFilter && !loadError && (
                    <Filter
                        gap="l"
                        items={jobData.jobs}
                        filters={[
                            {
                                name: 'Einsatzbereich',
                                type: 'select',
                                filterFn: value => ({ operationAreas }) =>
                                    value === '*' || operationAreas.includes(value),
                                options: jobData.filterItemsOperationAreas,
                            },
                            {
                                name: 'Vertragsart',
                                type: 'select',
                                filterFn: value => ({ contractType }) =>
                                    value === '*' || contractType.includes(value),
                                options: jobData.filterItemsContractTypes,
                            },
                        ]}
                        render={filteredJobs => (
                            <>
                                {filteredJobs <= 0 ? (
                                    <Messagebox error>
                                        Keine passenden Stellenangebote gefunden
                                    </Messagebox>
                                ) : (
                                    filteredJobs.map(({ id, title }) => (
                                        <JobItem
                                            id={id}
                                            title={title}
                                            openJobDetail={() => openJobDetail(id)}
                                            key={`jobitem-${id}`}
                                        />
                                    ))
                                )}
                            </>
                        )}
                    />
                )}
                {jobData && jobData.jobs.length > 0 && !showFilter && !loadError && (
                    <>
                        {jobData.jobs
                            .slice(0, limit || jobData.jobs.length)
                            .map(({ id, title }) => (
                                <JobItem
                                    id={id}
                                    title={title}
                                    openJobDetail={() => openJobDetail(id)}
                                    key={`jobitem-${id}`}
                                />
                            ))}
                    </>
                )}
            </Wrapper>
        </>
    );
};

Joblist.propTypes = {
    gap: gapable.propType,
    showFilter: PropTypes.bool,
    limit: PropTypes.number,
};

Joblist.defaultProps = {
    gap: null,
    showFilter: false,
    limit: null,
};

export default Joblist;
